.linkClass {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #3f51b5;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  
  .linkClassFirst {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .innerClass {
    display: flex;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    list-style-type: none;
    a:hover {
      z-index: 2;
      color: #3f51b5;
      background-color: #eeeeee;
      border-color: #ddd;
    }
  }
  
  .activeClass {
    z-index: 3;
    a {
      color: #fff;
      background-color: #3f51b5;
      border-color: #3f51b5;
      cursor: default;
    }
    a:hover {
      color: #fff;
      background-color: #3f51b5;
      border-color: #3f51b5;
      cursor: default;
    }
  }
  